import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';

import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AppService } from '../../../../utils/services/app.service';
import { AuthService } from '../../../../utils/services/auth.service';
import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';

@Component({
  selector: 'app-editalertsetting',
  templateUrl: './editalertsetting.component.html',
  styleUrls: ['./editalertsetting.component.scss']
})
export class EditAlertSettingComponent implements OnInit {
  public updateForm: FormGroup;
  isValidFormSubmitted = null;
  editTarget;
  tenantId: string = "";
  subscription1;
  subscription2;
  accounts;
  loginEmail;
  escapeTypes = [
    { name: 'in', value: 'in' },
    { name: 'out', value: 'out' },
    { name: 'both', value: 'both' },
  ];
  mailDuplicated: boolean = false;
  notification1UserName: string = "";
  notification2UserName: string = "";
  notification3UserName: string = "";
  notification4UserName: string = "";
  notification5UserName: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private appService: AppService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
  ) {
    try {
      this.editTarget = this.router.getCurrentNavigation().extras.state.target;
      this.auth.getLoginEmail().subscribe(result => {
        this.loginEmail = result;
      });
    }
    catch (e) {
      this.router.navigate([''])
    }
  }

  ngOnInit() {
    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId)),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    this.subscription1 = this.appService.getTenantId().subscribe(result => {
        this.tenantId = result;
        this.listAccountMaster();
    });

    this.subscription2 = DataStore.observe(AccountMaster).subscribe(msg => {
      this.listAccountMaster();
    });

    let regex = /^(?=.*hitoterrace[0-9]{9}@apps.hitoterrace.jp).*$/;
    let isAuthCecMaintenance = this.loginEmail.match(regex);

    // Hikvision専用アラート設定の場合
    if (this.editTarget.isHikvision) {
      // ログインユーザーがCEC保守管理者の場合
      if (isAuthCecMaintenance) {
        this.updateForm = this.formBuilder.group({
          name: [this.editTarget.name, [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
          description: [this.editTarget.description, [Validators.required, Validators.maxLength(300), Validators.pattern('[^",]+')]],
          notification1UserName: [this.editTarget.notification1UserName],
          notification2UserName: [this.editTarget.notification2UserName],
          notification3UserName: [this.editTarget.notification3UserName],
          notification4UserName: [this.editTarget.notification4UserName],
          notification5UserName: [this.editTarget.notification5UserName],
          isAlertEscape: [this.editTarget.isAlertEscape],
          isAlertHighFever: [this.editTarget.isAlertHighFever],
          isWatchOver: [this.editTarget.isWatchOver],
          isAutoUnlock: [this.editTarget.isAutoUnlock],
          isAlertUnKnown: [this.editTarget.isAlertUnKnown],
          escapeType: [this.editTarget.escapeType],
          highFeverThreshold: [this.editTarget.highFeverThreshold],
          mondayStartTime: [this.editTarget.mondayStartTime],
          mondayEndTime: [this.editTarget.mondayEndTime],
          tuesdayStartTime: [this.editTarget.tuesdayStartTime],
          tuesdayEndTime: [this.editTarget.tuesdayEndTime],
          wednesdayStartTime: [this.editTarget.wednesdayStartTime],
          wednesdayEndTime: [this.editTarget.wednesdayEndTime],
          thursdayStartTime: [this.editTarget.thursdayStartTime],
          thursdayEndTime: [this.editTarget.thursdayEndTime],
          fridayStartTime: [this.editTarget.fridayStartTime],
          fridayEndTime: [this.editTarget.fridayEndTime],
          saturdayStartTime: [this.editTarget.saturdayStartTime],
          saturdayEndTime: [this.editTarget.saturdayEndTime],
          sundayStartTime: [this.editTarget.sundayStartTime],
          sundayEndTime: [this.editTarget.sundayEndTime],
          isUnknownTarget: [this.editTarget.isUnknownTarget],
          isHikvision: [this.editTarget.isHikvision],
        });
      } else {
        // ログインユーザーがCEC保守管理者でない場合
        this.updateForm = this.formBuilder.group({
          name: [{value: this.editTarget.name, disabled: true}, [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
          description: [{value: this.editTarget.description, disabled: true}, [Validators.required, Validators.maxLength(300), Validators.pattern('[^",]+')]],
          notification1UserName: [this.editTarget.notification1UserName],
          notification2UserName: [this.editTarget.notification2UserName],
          notification3UserName: [this.editTarget.notification3UserName],
          notification4UserName: [this.editTarget.notification4UserName],
          notification5UserName: [this.editTarget.notification5UserName],
          isAlertEscape: [{value: this.editTarget.isAlertEscape, disabled: true}],
          isAlertHighFever: [this.editTarget.isAlertHighFever],
          isWatchOver: [{value: this.editTarget.isWatchOver, disabled: true}],
          isAutoUnlock: [{value: this.editTarget.isAutoUnlock, disabled: true}],
          isAlertUnKnown: [{value: this.editTarget.isAlertUnKnown, disabled: true}],
          escapeType: [{value: this.editTarget.escapeType, disabled: true}],
          highFeverThreshold: [{value: this.editTarget.highFeverThreshold, disabled: true}],
          mondayStartTime: [{value: this.editTarget.mondayStartTime, disabled: true}],
          mondayEndTime: [{value: this.editTarget.mondayEndTime, disabled: true}],
          tuesdayStartTime: [{value: this.editTarget.tuesdayStartTime, disabled: true}],
          tuesdayEndTime: [{value: this.editTarget.tuesdayEndTime, disabled: true}],
          wednesdayStartTime: [{value: this.editTarget.wednesdayStartTime, disabled: true}],
          wednesdayEndTime: [{value: this.editTarget.wednesdayEndTime, disabled: true}],
          thursdayStartTime: [{value: this.editTarget.thursdayStartTime, disabled: true}],
          thursdayEndTime: [{value: this.editTarget.thursdayEndTime, disabled: true}],
          fridayStartTime: [{value: this.editTarget.fridayStartTime, disabled: true}],
          fridayEndTime: [{value: this.editTarget.fridayEndTime, disabled: true}],
          saturdayStartTime: [{value: this.editTarget.saturdayStartTime, disabled: true}],
          saturdayEndTime: [{value: this.editTarget.saturdayEndTime, disabled: true}],
          sundayStartTime: [{value: this.editTarget.sundayStartTime, disabled: true}],
          sundayEndTime: [{value: this.editTarget.sundayEndTime, disabled: true}],
          isUnknownTarget: [{value: this.editTarget.isUnknownTarget, disabled: true}],
          isHikvision: [{value: this.editTarget.isHikvision, disabled: true}],
        });
      }
    } else {
      // 通常のアラート設定の場合
      if (isAuthCecMaintenance) {
        this.updateForm = this.formBuilder.group({
          name: [this.editTarget.name, [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
          description: [this.editTarget.description, [Validators.required, Validators.maxLength(300), Validators.pattern('[^",]+')]],
          notification1UserName: [this.editTarget.notification1UserName],
          notification2UserName: [this.editTarget.notification2UserName],
          notification3UserName: [this.editTarget.notification3UserName],
          notification4UserName: [this.editTarget.notification4UserName],
          notification5UserName: [this.editTarget.notification5UserName],
          isAlertEscape: [this.editTarget.isAlertEscape],
          isAlertHighFever: [this.editTarget.isAlertHighFever],
          isWatchOver: [this.editTarget.isWatchOver],
          isAutoUnlock: [this.editTarget.isAutoUnlock],
          isAlertUnKnown: [this.editTarget.isAlertUnKnown],
          escapeType: [this.editTarget.escapeType],
          highFeverThreshold: [this.editTarget.highFeverThreshold],
          mondayStartTime: [this.editTarget.mondayStartTime],
          mondayEndTime: [this.editTarget.mondayEndTime],
          tuesdayStartTime: [this.editTarget.tuesdayStartTime],
          tuesdayEndTime: [this.editTarget.tuesdayEndTime],
          wednesdayStartTime: [this.editTarget.wednesdayStartTime],
          wednesdayEndTime: [this.editTarget.wednesdayEndTime],
          thursdayStartTime: [this.editTarget.thursdayStartTime],
          thursdayEndTime: [this.editTarget.thursdayEndTime],
          fridayStartTime: [this.editTarget.fridayStartTime],
          fridayEndTime: [this.editTarget.fridayEndTime],
          saturdayStartTime: [this.editTarget.saturdayStartTime],
          saturdayEndTime: [this.editTarget.saturdayEndTime],
          sundayStartTime: [this.editTarget.sundayStartTime],
          sundayEndTime: [this.editTarget.sundayEndTime],
          isUnknownTarget: [this.editTarget.isUnknownTarget],
          isHikvision: [this.editTarget.isHikvision],
        });
      } else {
        this.updateForm = this.formBuilder.group({
          name: [this.editTarget.name, [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
          description: [this.editTarget.description, [Validators.required, Validators.maxLength(300), Validators.pattern('[^",]+')]],
          notification1UserName: [this.editTarget.notification1UserName],
          notification2UserName: [this.editTarget.notification2UserName],
          notification3UserName: [this.editTarget.notification3UserName],
          notification4UserName: [this.editTarget.notification4UserName],
          notification5UserName: [this.editTarget.notification5UserName],
          isAlertEscape: [this.editTarget.isAlertEscape],
          isAlertHighFever: [this.editTarget.isAlertHighFever],
          isWatchOver: [this.editTarget.isWatchOver],
          isAutoUnlock: [this.editTarget.isAutoUnlock],
          isAlertUnKnown: [this.editTarget.isAlertUnKnown],
          escapeType: [this.editTarget.escapeType],
          highFeverThreshold: [this.editTarget.highFeverThreshold],
          mondayStartTime: [this.editTarget.mondayStartTime],
          mondayEndTime: [this.editTarget.mondayEndTime],
          tuesdayStartTime: [this.editTarget.tuesdayStartTime],
          tuesdayEndTime: [this.editTarget.tuesdayEndTime],
          wednesdayStartTime: [this.editTarget.wednesdayStartTime],
          wednesdayEndTime: [this.editTarget.wednesdayEndTime],
          thursdayStartTime: [this.editTarget.thursdayStartTime],
          thursdayEndTime: [this.editTarget.thursdayEndTime],
          fridayStartTime: [this.editTarget.fridayStartTime],
          fridayEndTime: [this.editTarget.fridayEndTime],
          saturdayStartTime: [this.editTarget.saturdayStartTime],
          saturdayEndTime: [this.editTarget.saturdayEndTime],
          sundayStartTime: [this.editTarget.sundayStartTime],
          sundayEndTime: [this.editTarget.sundayEndTime],
          isUnknownTarget: [this.editTarget.isUnknownTarget],
          isHikvision: [{value: this.editTarget.isHikvision, disabled: true}],
        });
      }
    }
  }

  listAccountMaster() {
    let accountssub = from(DataStore.query(AccountMaster, c => c.tenantId("eq", this.tenantId)));
    accountssub.subscribe(resp => {
      this.accounts = [{ userName: '', email: '選択なし' }];
      this.accounts = this.accounts.concat(resp);

      this.accounts.map((element, index) => {
        let regex = /^(?=.*hitoterrace[0-9]{9}@apps.hitoterrace.jp).*$/;
        let match = element.email.match(regex);
        if (match) {
          // CECメンテナンスアカウントなので表示しない
          this.accounts.splice(index, 1)
        }
      });
    })
  }

  getloginEmail() {
    this.loginEmail = this.auth.getLoginEmail()
  }

  private get(id: string) {
    return from(DataStore.query(AlertSettingMaster, id));
  }

  onClickEditAlertSetting() {
    this.isValidFormSubmitted = true;
    if (this.updateForm.valid) {
      this.spinner.show();
      let name = this.updateForm.get("name").value;
      let description = this.updateForm.get("description").value;
      this.notification1UserName = this.updateForm.get("notification1UserName").value;
      this.notification2UserName = this.updateForm.get("notification2UserName").value;
      this.notification3UserName = this.updateForm.get("notification3UserName").value;
      this.notification4UserName = this.updateForm.get("notification4UserName").value;
      this.notification5UserName = this.updateForm.get("notification5UserName").value;
      let isAlertEscape = false;
      if (this.updateForm.get("isAlertEscape").value == true) {
        isAlertEscape = true;
      }
      let isAlertHighFever = false;
      if (this.updateForm.get("isAlertHighFever").value == true) {
        isAlertHighFever = true;
      }
      let isWatchOver = false;
      if (this.updateForm.get("isWatchOver").value == true) {
        isWatchOver = true;
      }
      let isAutoUnlock = false;
      if (this.updateForm.get("isAutoUnlock").value == true) {
        isAutoUnlock = true;
      }
      let isAlertUnKnown = false;
      if (this.updateForm.get("isAlertUnKnown").value == true) {
        isAlertUnKnown = true;
      }
      //let escapeType = this.updateForm.get("escapeType").value;
      let escapeType = "both";
      let highFeverThreshold = this.updateForm.get("highFeverThreshold").value.toString();
      let mondayStartTime = this.updateForm.get("mondayStartTime").value;
      let mondayEndTime = this.updateForm.get("mondayEndTime").value;
      let tuesdayStartTime = this.updateForm.get("tuesdayStartTime").value;
      let tuesdayEndTime = this.updateForm.get("tuesdayEndTime").value;
      let wednesdayStartTime = this.updateForm.get("wednesdayStartTime").value;
      let wednesdayEndTime = this.updateForm.get("wednesdayEndTime").value;
      let thursdayStartTime = this.updateForm.get("thursdayStartTime").value;
      let thursdayEndTime = this.updateForm.get("thursdayEndTime").value;
      let fridayStartTime = this.updateForm.get("fridayStartTime").value;
      let fridayEndTime = this.updateForm.get("fridayEndTime").value;
      let saturdayStartTime = this.updateForm.get("saturdayStartTime").value;
      let saturdayEndTime = this.updateForm.get("saturdayEndTime").value;
      let sundayStartTime = this.updateForm.get("sundayStartTime").value;
      let sundayEndTime = this.updateForm.get("sundayEndTime").value;
      let isUnknownTarget = false;
      if (this.updateForm.get("isUnknownTarget").value == true) {
        isUnknownTarget = true;
      }
      let isHikvision = false;
      if (this.updateForm.get("isHikvision").value == true) {
        isHikvision = true;
      }

      //メール送信先の重複チェック
      let mails = [];
      if(this.notification1UserName !== '' || this.notification1UserName){
        mails.push(this.notification1UserName);
      }
      if(this.notification2UserName !== '' || this.notification2UserName){
        mails.push(this.notification2UserName);
      }
      if(this.notification3UserName !== '' || this.notification3UserName){
        mails.push(this.notification3UserName);
      }
      if(this.notification4UserName !== '' || this.notification4UserName){
        mails.push(this.notification4UserName);
      }
      if(this.notification5UserName !== '' || this.notification5UserName){
        mails.push(this.notification5UserName);
      }

      let setMails = new Set(mails);
      if(setMails.size !== mails.length){
        this.toastr.error('メールアドレスが重複しています。', 'エラー');
        this.mailDuplicated = true;
        console.log('this.notification5UserName: '+ this.notification5UserName);
      }else{
        this.get(this.editTarget.id).subscribe(item => {
          DataStore.save(
            AlertSettingMaster.copyOf(item, updated => {
              updated.name = name;
              updated.description = description;
              updated.notification1UserName = this.notification1UserName;
              updated.notification2UserName = this.notification2UserName;
              updated.notification3UserName = this.notification3UserName;
              updated.notification4UserName = this.notification4UserName;
              updated.notification5UserName = this.notification5UserName;
              updated.isAlertEscape = isAlertEscape;
              updated.isAlertHighFever = isAlertHighFever;
              updated.isWatchOver = isWatchOver;
              updated.isAutoUnlock = isAutoUnlock;
              updated.isAlertUnKnown = isAlertUnKnown;
              updated.escapeType = escapeType;
              updated.highFeverThreshold = highFeverThreshold;
              updated.mondayStartTime = mondayStartTime;
              updated.mondayEndTime = mondayEndTime;
              updated.tuesdayStartTime = tuesdayStartTime;
              updated.tuesdayEndTime = tuesdayEndTime;
              updated.wednesdayStartTime = wednesdayStartTime;
              updated.wednesdayEndTime = wednesdayEndTime;
              updated.thursdayStartTime = thursdayStartTime;
              updated.thursdayEndTime = thursdayEndTime;
              updated.fridayStartTime = fridayStartTime;
              updated.fridayEndTime = fridayEndTime;
              updated.saturdayStartTime = saturdayStartTime;
              updated.saturdayEndTime = saturdayEndTime;
              updated.sundayStartTime = sundayStartTime;
              updated.sundayEndTime = sundayEndTime;
              updated.isUnknownTarget = isUnknownTarget;
              updated.isHikvision = isHikvision;
            })).then(result => {
              this.toastr.success('保存されました。', '成功');
              this.updateForm.reset();
              this.router.navigate(['/alertsettingmanagement'])
            }).catch(error => {
              this.toastr.error(error.message, 'エラー');
            });
        });
      }
      setTimeout(() => this.spinner.hide(), 1500);
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.updateForm.controls; }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    // this.subscription1.unsubscribe();
    // this.subscription2.unsubscribe();
  }
}
