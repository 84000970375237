import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, from } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { DataStore, syncExpression } from '@aws-amplify/datastore';

import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../models';
import { AppService } from '../../../utils/services/app.service';
import { AuthService } from '../../../utils/services/auth.service';

@Component({
  selector: 'app-alertsettingmanagement',
  templateUrl: './alertsettingmanagement.component.html',
  styleUrls: ['./alertsettingmanagement.component.scss']
})
export class AlertSettingManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  alertSettings;
  loginEmail;
  editTarget;
  tenantId: string = "";
  subscription1;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private appService: AppService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.subscription1 = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
        this.list();
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId)),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId)),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    this.dtOptions = {
      search: {
        regex: false,
      },
      scrollX: true,
      scrollCollapse: true,
      order: [[0, 'asc']],
      stateSave: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.list();
        this.auth.getLoginEmail().subscribe(result => {
          this.loginEmail = result;
        });
        this.alertSettings.subscribe(resp => {
          resp.map((element, index) => {
            let regex = /^(?=.*hitoterrace[0-9]{9}@apps.hitoterrace.jp).*$/;
            let isHikvision = element.isHikvision;
            let isAuthCecMaintenance = this.loginEmail.match(regex);
            // Hikvision専用アラート設定の場合
            if (isHikvision) {
              let alertSettingHik = {
                id: resp[index]['id'],
                name: resp[index]['name'],
                description: resp[index]['description'],
                escapeType: resp[index]['escapeType'],
                isAlertEscape: resp[index]['isAlertEscape'],
                isAlertHighFever: resp[index]['isAlertHighFever'],
                isWatchOver: resp[index]['isWatchOver'],
                isAutoUnlock: resp[index]['isAutoUnlock'],
                isAlertUnKnown: resp[index]['isAlertUnKnown'],
                isUnknownTarget: resp[index]['isUnknownTarget'],
                isHikvision: resp[index]['isHikvision'],
              }
              resp.splice(index, 1, alertSettingHik)

              // ログインアカウントがCEC保守管理者の場合、チェックする
              if (isAuthCecMaintenance) {
                resp[index]['isAuthCecMaintenance'] = true;
              }
            }
          });
          callback({
            data: resp,
          });
        })
      },
      columns: [
        {
          title: 'アラート名',
          data: 'name',
          name: 'name',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: 'アラート種別（離院）',
          name: 'isAlertEscape',
          render: function (data: any, type: any, full: any) {
            if (full.isAlertEscape) {
              return '<span class="label label-success margin-r-5">○</span>';
            }
            else {
              return '<span class="label label-success margin-r-5">×</span>';
            }
          }
        },
        {
          title: 'アラート種別（検温）',
          name: 'isAlertHighFever',
          render: function (data: any, type: any, full: any) {
            if (full.isAlertHighFever) {
              return '<span class="label label-success margin-r-5">○</span>';
            }
            else {
              return '<span class="label label-success margin-r-5">×</span>';
            }
          }
        },
        {
          title: 'アラート種別（院内）',
          name: 'isWatchOver',
          render: function (data: any, type: any, full: any) {
            if (full.isWatchOver) {
              return '<span class="label label-success margin-r-5">○</span>';
            }
            else {
              return '<span class="label label-success margin-r-5">×</span>';
            }
          }
        },
        {
          title: 'アラート種別（解錠）',
          name: 'isAutoUnlock',
          render: function (data: any, type: any, full: any) {
            if (full.isAutoUnlock) {
              return '<span class="label label-success margin-r-5">○</span>';
            }
            else {
              return '<span class="label label-success margin-r-5">×</span>';
            }
          }
        },
        {
          title: '操作',
          orderable: false,
          render: function (data: any, type: any, full: any) {
            if (full.isHikvision) {
              if (full.isAuthCecMaintenance) {
                return '<button type="button" edit-alertsetting-id="' + full.id + '" class="btn btn-xs btn-info margin-r-5">編集</button>' +
                        '<button type="button" delete-alertsetting-id="' + full.id + '" class="btn btn-xs btn-warning margin-r-5">削除</button></div>';
              } else {
                return '<button type="button" edit-alertsetting-id="' + full.id + '" class="btn btn-xs btn-info margin-r-5">編集</button>'
              }
            }

            return '<button type="button" edit-alertsetting-id="' + full.id + '" class="btn btn-xs btn-info margin-r-5">編集</button>' +
              '<button type="button" delete-alertsetting-id="' + full.id + '" class="btn btn-xs btn-warning margin-r-5">削除</button></div>';
          }
        },
        {
          title: '説明',
          data: 'description',
          name: 'description',
          render: $.fn.dataTable.render.text(),
        },
      ]
    };
  }

  list() {
    this.alertSettings = from(DataStore.query(AlertSettingMaster, c => c.tenantId("eq", this.tenantId)));
  }

  getloginEmail() {
    this.loginEmail = this.auth.getLoginEmail()
  }

  private get(id: string) {
    return from(DataStore.query(AlertSettingMaster, id));
  }

  onClickCreateAlertSetting() {
    this.router.navigate(['/alertsettingmanagement/create'])
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.list();
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.renderer.listen('document', 'click', (event) => {
      this.alertSettings.subscribe(resp => {
        if (event.target.hasAttribute("edit-alertsetting-id")) {
          resp.forEach(element => {
            if (element.id == event.target.getAttribute("edit-alertsetting-id")) {
              this.get(element.id).subscribe(item => {
                this.router.navigate(['/alertsettingmanagement/edit'], { state: { target: item } });
              });
            }
          });
        }
        else if (event.target.hasAttribute("delete-alertsetting-id")) {
          resp.forEach(element => {
            if (element.id == event.target.getAttribute("delete-alertsetting-id")) {
              this.get(element.id).subscribe(item => {
                this.router.navigate(['/alertsettingmanagement/delete'], { state: { target: item } });
              });
            }
          });
        }

      })
    });
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.dtTrigger.unsubscribe();
  }
}
