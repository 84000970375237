/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:4988506e-7bd1-4924-a349-10e9e2ea4d17",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_b2yUx1pED",
    "aws_user_pools_web_client_id": "5s5clbcu8d6lm40rcomhq4trk8",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://3slelbsu7rfejokvpyjh37dws4.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "hitoterracerestapi",
            "endpoint": "https://h72ln9ifof.execute-api.ap-northeast-1.amazonaws.com/devsec",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "hitoterrace-web-devsec",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d1zme3j2u9sp2q.cloudfront.net",
    "aws_mobile_analytics_app_id": "e588e15eceb049049d9ebed41c3e5bef",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_user_files_s3_bucket": "hitoterrace113313-devsec",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
